<template>
  <component-frame title="Step Wizard">
    <h3 id="alerts">Step Wizard</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="step-wizard">
            <div class="step completed">
              <a class="step-link" href="#">Applicant</a>
            </div>
            <div class="step completed">
              <a class="step-link" href="#">Charges, Liens, or Interests</a>
            </div>
            <div class="step completed">
              <a class="step-link" href="#"
                >Parcel Identifier &amp; Legal Description</a
              >
            </div>
            <div class="step active">
              <a class="step-link" href="#">Authorization Method</a>
            </div>
            <div class="step">
              <a class="step-link" href="#">Transferor</a>
            </div>
            <div class="step">
              <a class="step-link" href="#">Execution</a>
            </div>
            <div class="step disabled">
              <a class="step-link" href="#"
                >Attachments or Supporting Evidence</a
              >
            </div>
            <div class="step disabled">
              <a class="step-link" href="#">Validation Summary</a>
            </div>
            <div class="step disabled">
              <a class="step-link" href="#">View Electronic Instrument</a>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="step-wizard"&gt;
  &lt;div class="step completed"&gt;
    &lt;a class="step-link" href="#"&gt;Applicant&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step completed"&gt;
    &lt;a class="step-link" href="#"&gt;Charges, Liens, or Interests&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step completed"&gt;
    &lt;a class="step-link" href="#"&gt;Parcel Identifier &amp; Legal Description&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step active"&gt;
    &lt;a class="step-link" href="#"&gt;Authorization Method&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step"&gt;
    &lt;a class="step-link" href="#"&gt;Transferor&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step"&gt;
    &lt;a class="step-link" href="#"&gt;Execution&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step disabled"&gt;
    &lt;a class="step-link" href="#"&gt;Attachments or Supporting Evidence&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step disabled"&gt;
    &lt;a class="step-link" href="#"&gt;Validation Summary&lt;/a&gt;
  &lt;/div&gt;
  &lt;div class="step disabled"&gt;
    &lt;a class="step-link" href="#"&gt;View Electronic Instrument&lt;/a&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Use for navigation and progress indication in multi-step forms
          </li>
          <li>Use additional classes on the .step class to indicate status</li>
          <li>Available classes are: completed, active, and disabled</li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "feedback",
  data() {
    return {
      title: "Step Wizard"
    };
  }
};
</script>
